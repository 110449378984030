@media (min-width: 451px){
    .hamburger{
        display: none;
    }

    .navigation {
        width: 250px;
        height: 100vh;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        padding-top: 20px;
        background-color: white;
    }

    img.weblogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 250px;
    }

    .navItems{
        z-index:1001;
    }

    .navItemsMain li{
        text-align: left;
        font-variant: small-caps;
        font-family: 'Source Sans Pro', sans-serif;
        color: black;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 2px;
        display: block;
        text-decoration: none;
        padding-left: 0px;
        padding-bottom: 6px;
    }

    #youngAdult{
        margin-top: -1px;
    }

    .navItemsMain li li{
        text-align: left;
        font-variant: small-caps;
        font-family: 'Source Sans Pro', sans-serif;
        color: grey;
        opacity:  0.5;
        font-weight: 500;
        font-size: 5px;
        letter-spacing: 2px;
        display: block;
        text-decoration: none;
        padding-left: 20px;
        margin-top: -10px;
    }

    #navIcons {
        margin-left: 68px;
        padding-bottom: 15px
    }

    #navIcons a {
        padding-right: 5px;
    }

    #navContact {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 11px;
        text-align: left;
        color: black;
        text-decoration: none;
        padding-left: 68px;
    }

    #navContact a {
        color: black;
        padding-bottom: 50px;
        text-decoration: none;
    }

    li a:hover, #navContact a:hover {
        color: #5ea1a9;
        text-decoration: none;
    }

    #navIcons a{
        color: black;
    }

    #navIcons a:hover {
        color: #5ea1a9;
    }
}



@media (max-width: 450px){
    .hamburger{
        display: fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 10;
    }

    .hamburger{
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        z-index: 10;
    }

    .burger{
        width: 2 rem;
        height: 0.25rem;
        border-radius: 10px;
        background-color: black;
        transform-origin: 1px;
        transition: all 0.3s linear;
    }

    .navigation{
        display: none;
    }

    .navigation {
        margin-top: 13%;
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        padding-top: 20px;
        background-color: white;
    }

}

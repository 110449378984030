/* Content Pages */
@media (min-width: 451px){
  .page {
    margin-left: 250px; }
  .illustrationContentPage, .designContentPage, .designContentPageBook {
    height: 100%;
    margin: auto;
    text-align: center;
    max-width: 900px;
    z-index:-10; }
  .illustrationContentPage h2, .designContentPage h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    margin-top: 5%;
    letter-spacing: 2px;
    padding-bottom: 1%; }
  .designContentPageBook {
    margin-top: 5%;
  }
  .contentHMH {
    column-count: 2;
    max-width: 1000px; }
  #rapBattlePage {
    max-width: 500px; }
  #rapLink {
    color: #5ea1a9;
    text-decoration: none; }
  #portraitPage {
    max-width: 700px; }
  #hiddenPage {
    max-width: 500px; }
  .contentImage {
    width: 100%;
    display: block;
    margin-bottom: 50px; }

  .contentVideo{
    max-width: 100%;
    display: block;
    margin-bottom: 50px; 
  }
  #issuu {
    margin-bottom: 50px; }
  .contentText {
    font-size: 18px;
    padding-bottom: 3%;
    letter-spacing: 1px;
    line-height: 150%; }
  .contentTextHMH {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 0px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px; }
  .contentTextHMH2 {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 0px;
    font-family: "Crimson Text", serif;
    font-size: 11px; }
  .contentTextHMHTitle {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px; }
  .centered{
    text-align: center;
  }
  .row {
    display: table;
    clear: both; }
  .column {
    float: left;
    width: 45%;
    padding: 2%; }
  .bballcolumn {
    float: left;
    width: 31%;
    padding: 10px; }
  .chocolateText {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    text-align: center; }
  .ItalicText{
    font-style: italic;
  }
  .threeColumn{
    column-count: 3;
  }
  .interiorIllustrationGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
    grid-template-rows: repeat(1, max-content);
    grid-gap: 10px;
  }
  .interiorDesignGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, max-content));
    grid-template-rows: repeat(1, max-content);
    grid-gap: 10px;
  }
  .mobileNav{
      display: none;
    }
  
}
@media (max-width: 450px){
    .illustrationContentPage h2 {
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      margin-bottom: 5%;
      letter-spacing: 2px;
      text-align: center;
      margin-left: 5%;
      margin-right: 5%; }
    .contentText, .contentTextHMH, .contentTextHMH2, .contentTextHMHTitle {
      margin: 2% 5%; }

    .contentImage{
      margin-left: 5%;
      margin-right: 5%;
      width: 90%;
      height: auto;
      margin-bottom: 5%;
    }
    .contentVideo{
      max-width: 100%;
      display: block;
      z-index:-10;
    }
    .designContent, .designContentPageBook{
      padding-top: 50px;
    }

}





/* Nav Bar */
	.topnav, .mobileDesign, .mobileIllustration, #navLogo, img.mobilelogo{
        display: none;
    }

    .navigation {
        width: 250px;
        height: 100vh;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        padding-top: 20px;
        background-color: white;
    }

    img.weblogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 250px;
    }

    .navItems a {
        text-align: left;
        font-variant: small-caps;
        font-family: 'Source Sans Pro', sans-serif;
        color: black;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 2px;
        display: block;
        text-decoration: none;
        padding-left: 68px;
        padding-bottom: 6px;
    }

    #navIcons {
        margin-left: 68px;
    }

    i {
        padding-right: 5px;
    }

    #navContact {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 11px;
        text-align: left;
        color: black;
        text-decoration: none;
        padding-left: 68px;
    }

    #navContact a {
        color: black;
        padding-bottom: 50px;
        text-decoration: none;
    }

    li a:hover, #navContact a:hover {
        color: #5ea1a9;
        text-decoration: none;
    }

    #navIcons a{
        color: black;
    }

    #navIcons a:hover {
        color: #5ea1a9;
    }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 451px){
    .hamburger{
        display: none;
    }

    .navigation {
        width: 250px;
        height: 100vh;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        padding-top: 20px;
        background-color: white;
    }

    img.weblogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 250px;
    }

    .navItems{
        z-index:1001;
    }

    .navItemsMain li{
        text-align: left;
        -webkit-font-feature-settings: "smcp";
                font-feature-settings: "smcp";
        font-variant: small-caps;
        font-family: 'Source Sans Pro', sans-serif;
        color: black;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 2px;
        display: block;
        text-decoration: none;
        padding-left: 0px;
        padding-bottom: 6px;
    }

    #youngAdult{
        margin-top: -1px;
    }

    .navItemsMain li li{
        text-align: left;
        -webkit-font-feature-settings: "smcp";
                font-feature-settings: "smcp";
        font-variant: small-caps;
        font-family: 'Source Sans Pro', sans-serif;
        color: grey;
        opacity:  0.5;
        font-weight: 500;
        font-size: 5px;
        letter-spacing: 2px;
        display: block;
        text-decoration: none;
        padding-left: 20px;
        margin-top: -10px;
    }

    #navIcons {
        margin-left: 68px;
        padding-bottom: 15px
    }

    #navIcons a {
        padding-right: 5px;
    }

    #navContact {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 11px;
        text-align: left;
        color: black;
        text-decoration: none;
        padding-left: 68px;
    }

    #navContact a {
        color: black;
        padding-bottom: 50px;
        text-decoration: none;
    }

    li a:hover, #navContact a:hover {
        color: #5ea1a9;
        text-decoration: none;
    }

    #navIcons a{
        color: black;
    }

    #navIcons a:hover {
        color: #5ea1a9;
    }
}



@media (max-width: 450px){
    .hamburger{
        display: fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 10;
    }

    .hamburger{
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        z-index: 10;
    }

    .burger{
        width: 2 rem;
        height: 0.25rem;
        border-radius: 10px;
        background-color: black;
        -webkit-transform-origin: 1px;
                transform-origin: 1px;
        transition: all 0.3s linear;
    }

    .navigation{
        display: none;
    }

    .navigation {
        margin-top: 13%;
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        padding-top: 20px;
        background-color: white;
    }

}

@media (max-width: 450px){
	.illustration-grid-container {
    	display: none; 
    }
    .illustration-mobile{
    	display: block;
		margin-top: 50px;

    }
  	.gridImage {
	    margin-left: 5%;
	    margin-right: 5%;
	    width: 90%;
	    height: auto;
	    margin-bottom: 5%; 
	}
	.illustrationOverlay{
		display: none;
	}
}

@media (min-width: 451px){
	.illustration-mobile{
		display:none;
	}
	.illustration-grid-container {
	  display: grid;
	  grid-template-columns: repeat(10, 1fr);
	  grid-template-rows: repeat(10, minmax(250px, -webkit-max-content));
	  grid-template-rows: repeat(10, minmax(250px, max-content));
	  grid-gap: 10px;
	  margin-left: 250px;
	}
	.illustrationOverlay a {
	  color: white;
	  font-family: "Source Sans Pro", sans-serif;
	  display: block;
	  width: 100%;
	  height: 80%;
	  font-size: 2vw;
	  text-align: center;
	  text-decoration: none; }
	.illustrationOverlay a:hover {
	  color: white;
	  text-decoration: none; }
	.gridImage, .illustrationOverlay {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  cursor: pointer; }
	.illustrationOverlay {
	  opacity: 0;
	  z-index: 1;
	  color: white; }
	.illustrationOverlay:hover {
	  opacity: 1;
	  transition-duration: 500ms; }
	.illustrationOverlay h3 {
	  padding-left: 7%;
	  padding-top: 4%;
	  font-size: 1vw;
	  font-family: "Open Sans", sans-serif;
	  text-transform: uppercase;
	  letter-spacing: 2px;
	  font-weight: 700; }

	
	#WordsHavePower, #WordsHavePowerOverlay {
		grid-column: 11/-8;
		grid-row: 1/2; }
	#WordsHavePowerOverlay {
		background-color: #c75500; }
	#WordsHavePowerOverlay a {
		padding-top: 20%;
	 }

	#YeYe, #YeYeOverlay {
		grid-column: 1/4;
		grid-row: 1/2; }
	#YeYeOverlay {
		background-color: #fde1be; }
	#YeYeOverlay a {
		padding-top: 50%;}

	#panda, #pandaOverlay {
	  grid-column: 11/-5;
	  grid-row: 3/4; }
	#pandaOverlay {
	  background-color: #e0e0cb; }
	#pandaOverlay a {
	  padding-top: 30%;
	  height: 50%; }


	#secretSanta, #secretSantaOverlay {
	  grid-column: 1/7;
	  grid-row: 3/4; }
	#secretSantaOverlay {
	  background-color: #eec3b3; }
	#secretSantaOverlay a {
	  padding-top: 18%; }
	

	#emperor, #emperorOverlay {
	  grid-column: 1/5;
	  grid-row: 4/5; }
	#emperorOverlay {
	  background-color: #9cced4; }
	#emperorOverlay a {
	  padding-top: 15vh;
	  line-height: 100%; }

	#BookReviewEditorial, #BookReviewEditorialOverlay {
	  grid-column: 1/7;
	  grid-row: 2/3; }
	#BookReviewEditorialOverlay {
	  background-color: #f9ed43; }
	#BookReviewEditorialOverlay a {
	  padding-top: 16%; }

	#redriding, #redridingOverlay {
	  grid-column: 11/-5;
	  grid-row: 2/3; }
	#redridingOverlay {
	  background-color: #be442c; }
	#redridingOverlay a {
	  padding-top: 25%; }

	#christmas, #christmasOverlay {
	  grid-column: 11/-6;
	  grid-row: 5/6; }
	#christmasOverlay {
	  background-color: #7bc79f; }
	#christmasOverlay a {
	  padding-top: 18vh; }


	#SincerelyDiary, #SincerelyDiaryOverlay {
	  grid-column: 1/6;
	  grid-row: 5/6; }
	#SincerelyDiaryOverlay {
	  background-color: #8ccfce; }
	#SincerelyDiaryOverlay a {
	  padding-top: 18vh; }

	#potatochips, #potatochipsOverlay {
	grid-column: 5/7;
	grid-row: 4/5; }
	#potatochipsOverlay {
	background-color: #5c735e; }
	#potatochipsOverlay a {
	padding-top: 15vh;
	height: 55%; }	
	#covid, #covidOverlay {
	grid-column: 11/-5;
	grid-row: 4/5; }
	#covidOverlay {
	background-color: #edd9a7; }
	#covidOverlay a {
	padding-top: 15vh; }

	#buddha, #buddhaOverlay {
	  grid-column: 1/6;
	  grid-row: 6/7; }
	#buddhaOverlay {
	  background-color: #f0aeaf; }
	#buddhaOverlay a {
	  padding-top: 30vh; }
	#sick, #sickOverlay {
	  grid-column: 1/6;
	  grid-row: 6/7; }
	#sickOverlay {
	  background-color: #aaa9a9; }
	#sickOverlay a {
	  padding-top: 20vh; }
	#bear, #bearOverlay {
	  grid-column: 1/6;
	  grid-row: 7/8; }
	#bearOverlay {
	  background-color: #97c4af; }
	#bearOverlay a {
	  padding-top: 25vh; }
	#veggie, #veggieOverlay {
	  grid-column: 8/11;
	  grid-row: 10/11; }
	#veggieOverlay {
	  background-color: #e9c36a; }
	#veggieOverlay a {
	  padding-top: 30vh; }
	
	#girlsticker, #girlstickerOverlay {
	  grid-column: 11/-6;
	  grid-row: 6/7; }
	#girlstickerOverlay {
	  background-color: #6e846f; }
	#girlstickerOverlay a {
	  padding-top: 30vh; }
	#rabbitsticker, #rabbitstickerOverlay {
	  grid-column: 11/-6;
	  grid-row: 7/8; }
	#rabbitstickerOverlay {
	  background-color: #eacfa4; }
	#rabbitstickerOverlay a {
	  padding-top: 25vh; }

	
	#ideo, #ideoOverlay {
	  grid-column: 4/8;
	  grid-row: 8/9; }
	#ideoOverlay {
	  background-color: #20343b; }
	#ideoOverlay a {
	  padding-top: 20vh; }
	#minniegary, #minniegaryOverlay {
	  grid-column: 8/11;
	  grid-row: 8/9; }
	#minniegaryOverlay {
	  background-color: #f68e73; }
	#minniegaryOverlay a {
	  padding-top: 20vh; }
	#minibuddha, #minibuddhaOverlay {
	  grid-column: 1/4;
	  grid-row: 8/9; }
	#minibuddhaOverlay {
	  background-color: #edd288; }
	#minibuddhaOverlay a {
	  padding-top: 25vh; }	

	#rapBattle, #rapBattleOverlay {
	  grid-column: 5/8;
	  grid-row: 10/11; }
	#rapBattleOverlay {
	  background-color: #25323c; }
	#rapBattleOverlay a {
	  padding-top: 30vh; }	

	#levinapkin, #levinapkinOverlay {
	  grid-column: 1/5;
	  grid-row: 10/11; }
	#levinapkinOverlay {
	  background-color: #ffdecf; }
	#levinapkinOverlay a {
	  padding-top: 30vh; }	

	#medschool, #medschoolOverlay {
	  grid-column: 1/3;
	  grid-row: 9/10; }
	#medschoolOverlay {
	  background-color: #373a45; }
	#medschoolOverlay a {
	  padding-top: 30vh; }	
	#yogatiger, #yogatigerOverlay {
	  grid-column: 11/-9;
	  grid-row: 9/10; }
	#yogatigerOverlay {
	  background-color: #97b492; }
	#yogatigerOverlay a {
	  padding-top: 25vh; }	




}
@media (max-width: 450px){
	.design-grid-container-main{
		display: none;
	}
	.design-mobile{
		display: grid;
		margin-top: 50px;
		-webkit-user-select: none;
		        user-select: none;
	}
  	.designImage {
	    margin-left: 5%;
	    margin-right: 5%;
	    width: 90%;
	    height: auto;
	    margin-bottom: 5%; 
	}
	.designOverlay{
		display: none;
	}
	.mobileNav{
		display: block;
		height: 50px;
		width: 100%;
		position: fixed;
		top: 0;
		background-color: white;
	}
}

@media (min-width: 451px){
	.design-grid-container-main{
	    display: grid;
	    grid-template-columns: repeat(auto-fit, minmax(400px, -webkit-max-content));
	    grid-template-columns: repeat(auto-fit, minmax(400px, max-content));
	    grid-template-rows: repeat(1, -webkit-max-content);
	    grid-template-rows: repeat(1, max-content);
	    margin-left: 250px;
	    grid-gap: 10px;
	}

	.design-mobile{
		display: none;
	}

	.designProject{
	    position: relative;
	}

	.designImage{
	    width: 100%;
	    height: 100%;
	    z-index: -1;
	}

	.designOverlay{
	    position: absolute;
	    text-align: center;
	    width: 100%;
	    height: 100%;
	    left: 0;
	    top: 0;
	    opacity: 0;
	}

	.designOverlay a{
	    display: inline-block;
	    width: 100%;
	    height: 100%;
	    text-decoration: none;
	    color: grey;
	    background-color: white;
	    text-align: center;
	    font-size: 2vw;
	    font-family: "Source Sans Pro", sans-serif;
	    z-index:1;
	    padding-top: 33%;
	}

	.designOverlay:hover{
	    opacity: 0.5;
	}
}
@media (max-width: 450px){
    #resumeDownload {
      display: none; }
    .aboutTitle {
      text-align: center;
      margin-top: 0;
      margin-bottom: 3%;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      letter-spacing: 1%; }
    .aboutDescription {
      display: block;
      margin-top: 0;
      font-family: "Source Sans Pro", sans-serif; }
    #aboutDescription {
      margin-left: 5%;
      margin-right: 5%; }
    #aboutImage{
      margin-left: 5%;
      margin-right: 5%;
      width: 90%;
      height: auto;
      margin-bottom: 5%; }
}

@media (min-width: 451px){
    .aboutDescription {
        margin-left: 250px;
        padding-right: 10%;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 17px;
        letter-spacing: 1px;
        line-height: 150%; }
    .aboutColumn {
        float: left;
        width: 50%; }
    #aboutImage {
        display: block;
        width: 70%;
        margin-left: 25%;
        margin-right: 5%; }
    .aboutColumn a{
        display: block;
        text-align: center;
        padding-left: 20%;
        padding-top: 1%;
        text-decoration: none;
        color: grey;
        -webkit-font-feature-settings: "smcp";
                font-feature-settings: "smcp";
        font-variant: small-caps;
        letter-spacing: 2px;
        font-size: 18px; }
    .aboutColumn a:hover {
        color: #5ea1a9; }
    #aboutDescription a {
        text-decoration: none;
        color: grey;} 
    #aboutDescription a:hover {
        color: #5ea1a9;
        text-decoration: none; }
    .aboutTitle {
        text-align: center;
        font-family: "Source Sans Pro", sans-serif;
        margin-top: 5%;
        padding-bottom: 5%;
        font-weight: 600;
        letter-spacing: 1px; }
}

@media (max-width: 450px){
	.design-grid-container{
		display: none;
	}
	.design-mobile{
		display: block;
	}
  	.designImage {
	    margin-left: 5%;
	    margin-right: 5%;
	    width: 90%;
	    height: auto;
	    margin-bottom: 5%; 
	}
	.designOverlay{
		display: none;
	}
}

@media (min-width: 451px){
	.design-grid-container{
	    display: grid;
	    grid-template-columns: repeat(auto-fit, minmax(580px, -webkit-max-content));
	    grid-template-columns: repeat(auto-fit, minmax(580px, max-content));
	    grid-template-rows: repeat(1, -webkit-max-content);
	    grid-template-rows: repeat(1, max-content);
	    margin-left: 250px;
	    grid-gap: 10px;
	}

	.design-mobile{
		display: none;
	}

	.designProject{
	    position: relative;
	}

	.designImage{
	    width: 100%;
	    height: 100%;
	    z-index: -1;
	}

	.designOverlay{
	    position: absolute;
	    text-align: center;
	    width: 100%;
	    height: 100%;
	    left: 0;
	    top: 0;
	    opacity: 0;
	}

	.designOverlay a{
	    display: inline-block;
	    width: 100%;
	    height: 100%;
	    text-decoration: none;
	    color: grey;
	    background-color: white;
	    text-align: center;
	    font-size: 2vw;
	    font-family: "Source Sans Pro", sans-serif;
	    z-index:1;
	    padding-top: 33%;
	}

	.designOverlay:hover{
	    opacity: 0.5;
	}
}
@media (max-width: 450px){
	.uiux-grid-container-main{
		display: none;
	}
	.uiux-mobile{
		display: grid;
		margin-top: 50px;
		-webkit-user-select: none;
		        user-select: none;
	}
  	.uiuxImage {
	    margin-left: 5%;
	    margin-right: 5%;
	    width: 90%;
	    height: auto;
	    margin-bottom: 5%; 
	}
	.uiuxOverlay{
		display: none;
	}
	.mobileNav{
		display: block;
		height: 50px;
		width: 100%;
		position: fixed;
		top: 0;
		background-color: white;
	}
}

@media (min-width: 451px){
	.uiux-grid-container-main{
	    display: grid;
	    grid-template-columns: repeat(auto-fill, minmax(600px, -webkit-max-content));
	    grid-template-columns: repeat(auto-fill, minmax(600px, max-content));
	    grid-template-rows: repeat(1, -webkit-max-content);
	    grid-template-rows: repeat(1, max-content);
	    overflow:visible;
	    margin-left: 250px;
	    grid-gap: 10px;
	}

	.uiux-mobile{
		display: none;
	}

	.uiuxProject{
	    position: relative;
	}

	.uiuxImage{
	    width: 100%;
	    height: 100%;
	    z-index: -1;
	}

	.uiuxOverlay{
	    position: absolute;
	    text-align: center;
	    width: 100%;
	    height: 100%;
	    left: 0;
	    top: 0;
	    opacity: 0;
	}

	.uiuxOverlay a{
	    display: inline-block;
	    width: 100%;
	    height: 100%;
	    text-decoration: none;
	    color: grey;
	    background-color: white;
	    text-align: center;
	    font-size: 2vw;
	    font-family: "Source Sans Pro", sans-serif;
	    z-index:1;
	    padding-top: 33%;
	}

	.uiuxOverlay:hover{
	    opacity: 0.5;
	}
}
/* Content Pages */
@media (min-width: 451px){
  .page {
    margin-left: 250px; }
  .illustrationContentPage, .designContentPage, .designContentPageBook {
    height: 100%;
    margin: auto;
    text-align: center;
    max-width: 900px;
    z-index:-10; }
  .illustrationContentPage h2, .designContentPage h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    margin-top: 5%;
    letter-spacing: 2px;
    padding-bottom: 1%; }
  .designContentPageBook {
    margin-top: 5%;
  }
  .contentHMH {
    -webkit-column-count: 2;
            column-count: 2;
    max-width: 1000px; }
  #rapBattlePage {
    max-width: 500px; }
  #rapLink {
    color: #5ea1a9;
    text-decoration: none; }
  #portraitPage {
    max-width: 700px; }
  #hiddenPage {
    max-width: 500px; }
  .contentImage {
    width: 100%;
    display: block;
    margin-bottom: 50px; }

  .contentVideo{
    max-width: 100%;
    display: block;
    margin-bottom: 50px; 
  }
  #issuu {
    margin-bottom: 50px; }
  .contentText {
    font-size: 18px;
    padding-bottom: 3%;
    letter-spacing: 1px;
    line-height: 150%; }
  .contentTextHMH {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 0px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px; }
  .contentTextHMH2 {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 0px;
    font-family: "Crimson Text", serif;
    font-size: 11px; }
  .contentTextHMHTitle {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px; }
  .centered{
    text-align: center;
  }
  .row {
    display: table;
    clear: both; }
  .column {
    float: left;
    width: 45%;
    padding: 2%; }
  .bballcolumn {
    float: left;
    width: 31%;
    padding: 10px; }
  .chocolateText {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    text-align: center; }
  .ItalicText{
    font-style: italic;
  }
  .threeColumn{
    -webkit-column-count: 3;
            column-count: 3;
  }
  .interiorIllustrationGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, -webkit-max-content));
    grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
    grid-template-rows: repeat(1, -webkit-max-content);
    grid-template-rows: repeat(1, max-content);
    grid-gap: 10px;
  }
  .interiorDesignGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, -webkit-max-content));
    grid-template-columns: repeat(auto-fit, minmax(350px, max-content));
    grid-template-rows: repeat(1, -webkit-max-content);
    grid-template-rows: repeat(1, max-content);
    grid-gap: 10px;
  }
  .mobileNav{
      display: none;
    }
  
}
@media (max-width: 450px){
    .illustrationContentPage h2 {
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      margin-bottom: 5%;
      letter-spacing: 2px;
      text-align: center;
      margin-left: 5%;
      margin-right: 5%; }
    .contentText, .contentTextHMH, .contentTextHMH2, .contentTextHMHTitle {
      margin: 2% 5%; }

    .contentImage{
      margin-left: 5%;
      margin-right: 5%;
      width: 90%;
      height: auto;
      margin-bottom: 5%;
    }
    .contentVideo{
      max-width: 100%;
      display: block;
      z-index:-10;
    }
    .designContent, .designContentPageBook{
      padding-top: 50px;
    }

}





/* Nav Bar */
	.topnav, .mobileDesign, .mobileIllustration, #navLogo, img.mobilelogo{
        display: none;
    }

    .navigation {
        width: 250px;
        height: 100vh;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        padding-top: 20px;
        background-color: white;
    }

    img.weblogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 250px;
    }

    .navItems a {
        text-align: left;
        -webkit-font-feature-settings: "smcp";
                font-feature-settings: "smcp";
        font-variant: small-caps;
        font-family: 'Source Sans Pro', sans-serif;
        color: black;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 2px;
        display: block;
        text-decoration: none;
        padding-left: 68px;
        padding-bottom: 6px;
    }

    #navIcons {
        margin-left: 68px;
    }

    i {
        padding-right: 5px;
    }

    #navContact {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 11px;
        text-align: left;
        color: black;
        text-decoration: none;
        padding-left: 68px;
    }

    #navContact a {
        color: black;
        padding-bottom: 50px;
        text-decoration: none;
    }

    li a:hover, #navContact a:hover {
        color: #5ea1a9;
        text-decoration: none;
    }

    #navIcons a{
        color: black;
    }

    #navIcons a:hover {
        color: #5ea1a9;
    }

@media (max-width: 450px){
	.illustration-grid-container {
    	display: none; 
    }
    .illustration-mobile{
    	display: block;
		margin-top: 50px;

    }
  	.gridImage {
	    margin-left: 5%;
	    margin-right: 5%;
	    width: 90%;
	    height: auto;
	    margin-bottom: 5%; 
	}
	.illustrationOverlay{
		display: none;
	}
}

@media (min-width: 451px){
	.illustration-mobile{
		display:none;
	}
	.illustration-grid-container {
	  display: grid;
	  grid-template-columns: repeat(10, 1fr);
	  grid-template-rows: repeat(10, minmax(250px, max-content));
	  grid-gap: 10px;
	  margin-left: 250px;
	}
	.illustrationOverlay a {
	  color: white;
	  font-family: "Source Sans Pro", sans-serif;
	  display: block;
	  width: 100%;
	  height: 80%;
	  font-size: 2vw;
	  text-align: center;
	  text-decoration: none; }
	.illustrationOverlay a:hover {
	  color: white;
	  text-decoration: none; }
	.gridImage, .illustrationOverlay {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  cursor: pointer; }
	.illustrationOverlay {
	  opacity: 0;
	  z-index: 1;
	  color: white; }
	.illustrationOverlay:hover {
	  opacity: 1;
	  transition-duration: 500ms; }
	.illustrationOverlay h3 {
	  padding-left: 7%;
	  padding-top: 4%;
	  font-size: 1vw;
	  font-family: "Open Sans", sans-serif;
	  text-transform: uppercase;
	  letter-spacing: 2px;
	  font-weight: 700; }

	
	#WordsHavePower, #WordsHavePowerOverlay {
		grid-column: 11/-8;
		grid-row: 1/2; }
	#WordsHavePowerOverlay {
		background-color: #c75500; }
	#WordsHavePowerOverlay a {
		padding-top: 20%;
	 }

	#YeYe, #YeYeOverlay {
		grid-column: 1/4;
		grid-row: 1/2; }
	#YeYeOverlay {
		background-color: #fde1be; }
	#YeYeOverlay a {
		padding-top: 50%;}

	#panda, #pandaOverlay {
	  grid-column: 11/-5;
	  grid-row: 3/4; }
	#pandaOverlay {
	  background-color: #e0e0cb; }
	#pandaOverlay a {
	  padding-top: 30%;
	  height: 50%; }


	#secretSanta, #secretSantaOverlay {
	  grid-column: 1/7;
	  grid-row: 3/4; }
	#secretSantaOverlay {
	  background-color: #eec3b3; }
	#secretSantaOverlay a {
	  padding-top: 18%; }
	

	#emperor, #emperorOverlay {
	  grid-column: 1/5;
	  grid-row: 4/5; }
	#emperorOverlay {
	  background-color: #9cced4; }
	#emperorOverlay a {
	  padding-top: 15vh;
	  line-height: 100%; }

	#BookReviewEditorial, #BookReviewEditorialOverlay {
	  grid-column: 1/7;
	  grid-row: 2/3; }
	#BookReviewEditorialOverlay {
	  background-color: #f9ed43; }
	#BookReviewEditorialOverlay a {
	  padding-top: 16%; }

	#redriding, #redridingOverlay {
	  grid-column: 11/-5;
	  grid-row: 2/3; }
	#redridingOverlay {
	  background-color: #be442c; }
	#redridingOverlay a {
	  padding-top: 25%; }

	#christmas, #christmasOverlay {
	  grid-column: 11/-6;
	  grid-row: 5/6; }
	#christmasOverlay {
	  background-color: #7bc79f; }
	#christmasOverlay a {
	  padding-top: 18vh; }


	#SincerelyDiary, #SincerelyDiaryOverlay {
	  grid-column: 1/6;
	  grid-row: 5/6; }
	#SincerelyDiaryOverlay {
	  background-color: #8ccfce; }
	#SincerelyDiaryOverlay a {
	  padding-top: 18vh; }

	#potatochips, #potatochipsOverlay {
	grid-column: 5/7;
	grid-row: 4/5; }
	#potatochipsOverlay {
	background-color: #5c735e; }
	#potatochipsOverlay a {
	padding-top: 15vh;
	height: 55%; }	
	#covid, #covidOverlay {
	grid-column: 11/-5;
	grid-row: 4/5; }
	#covidOverlay {
	background-color: #edd9a7; }
	#covidOverlay a {
	padding-top: 15vh; }

	#buddha, #buddhaOverlay {
	  grid-column: 1/6;
	  grid-row: 6/7; }
	#buddhaOverlay {
	  background-color: #f0aeaf; }
	#buddhaOverlay a {
	  padding-top: 30vh; }
	#sick, #sickOverlay {
	  grid-column: 1/6;
	  grid-row: 6/7; }
	#sickOverlay {
	  background-color: #aaa9a9; }
	#sickOverlay a {
	  padding-top: 20vh; }
	#bear, #bearOverlay {
	  grid-column: 1/6;
	  grid-row: 7/8; }
	#bearOverlay {
	  background-color: #97c4af; }
	#bearOverlay a {
	  padding-top: 25vh; }
	#veggie, #veggieOverlay {
	  grid-column: 8/11;
	  grid-row: 10/11; }
	#veggieOverlay {
	  background-color: #e9c36a; }
	#veggieOverlay a {
	  padding-top: 30vh; }
	
	#girlsticker, #girlstickerOverlay {
	  grid-column: 11/-6;
	  grid-row: 6/7; }
	#girlstickerOverlay {
	  background-color: #6e846f; }
	#girlstickerOverlay a {
	  padding-top: 30vh; }
	#rabbitsticker, #rabbitstickerOverlay {
	  grid-column: 11/-6;
	  grid-row: 7/8; }
	#rabbitstickerOverlay {
	  background-color: #eacfa4; }
	#rabbitstickerOverlay a {
	  padding-top: 25vh; }

	
	#ideo, #ideoOverlay {
	  grid-column: 4/8;
	  grid-row: 8/9; }
	#ideoOverlay {
	  background-color: #20343b; }
	#ideoOverlay a {
	  padding-top: 20vh; }
	#minniegary, #minniegaryOverlay {
	  grid-column: 8/11;
	  grid-row: 8/9; }
	#minniegaryOverlay {
	  background-color: #f68e73; }
	#minniegaryOverlay a {
	  padding-top: 20vh; }
	#minibuddha, #minibuddhaOverlay {
	  grid-column: 1/4;
	  grid-row: 8/9; }
	#minibuddhaOverlay {
	  background-color: #edd288; }
	#minibuddhaOverlay a {
	  padding-top: 25vh; }	

	#rapBattle, #rapBattleOverlay {
	  grid-column: 5/8;
	  grid-row: 10/11; }
	#rapBattleOverlay {
	  background-color: #25323c; }
	#rapBattleOverlay a {
	  padding-top: 30vh; }	

	#levinapkin, #levinapkinOverlay {
	  grid-column: 1/5;
	  grid-row: 10/11; }
	#levinapkinOverlay {
	  background-color: #ffdecf; }
	#levinapkinOverlay a {
	  padding-top: 30vh; }	

	#medschool, #medschoolOverlay {
	  grid-column: 1/3;
	  grid-row: 9/10; }
	#medschoolOverlay {
	  background-color: #373a45; }
	#medschoolOverlay a {
	  padding-top: 30vh; }	
	#yogatiger, #yogatigerOverlay {
	  grid-column: 11/-9;
	  grid-row: 9/10; }
	#yogatigerOverlay {
	  background-color: #97b492; }
	#yogatigerOverlay a {
	  padding-top: 25vh; }	




}